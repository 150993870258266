strong {
    color: black;
    font-weight: 600;
    text-transform: uppercase;
    font-weight: bolder;
    padding: 2px;
    border-radius: 3px;
}

.address-text-area,
.thanks-text-area{
    width: 99%;
    height: 150px;
}

#rgpd_title_label{
    margin: 0 10px;
}

#rgpd_text_label{
    margin-top: 5px;
    padding: 10px;
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}