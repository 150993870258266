.bible-component{
    cursor: pointer;
    text-align: justify;
}

.bible-component .verse-text {
    font-size: larger;
}

.bible-component .verse-number {
    vertical-align:super;
    color: #035992; 
    font-weight: bolder;
    font-size: smaller;
}

h2{
    color:#212529;
}
