.fixed-menu-top {
    top: -40px!important;
    transform: translateY(40px);
    transition: transform .3s;
    transition: background-color 1s;

}

#navbar_top{
    /*background-color: transparent!important;*/
    /*background-color: #cbac59!important;*/
    box-shadow:0px 2px 5px 0px rgb(0 0 0 / 30%);
    background-size: cover;   

    position: fixed;
    right: 0;
    left: 0;
    top:0;
    z-index: 501;
}

#main_nav{
    transition: ease 1s;
}