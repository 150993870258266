/*
JLRPT2 - NestedTitlePicture.scss
*/
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";
@import url('https://fonts.cdnfonts.com/css/avenir-next-lt-pro');
$avenir-next-lt-pro-family: 'Avenir Next LT Pro', sans-serif; 
$title-color: white;

h1, h2, h3, h4, h5 {
    font-family: $avenir-next-lt-pro-family;
    color: $title-color;
}

.nested-component{
    position: relative;

        .nested-container{
            height: 100%;
            width: 100%;
            background-position: center ;
            background-size: cover;
            display: flex;
            align-items: center;
            justify-content: center;
            
            .background-filter{
                height: 100%;
                width: 100%;
                background-position: center;
                background-size: cover;
                filter:brightness(70%);
            }

            .nested-title{
                @extend h1;
                mix-blend-mode:lighten;
                position: absolute;
            }
        }
    }