/*
JLRPT2 - HomePage-template.scss
*/

@import "../../../node_modules/bootstrap/scss/bootstrap.scss";
@import url('https://fonts.cdnfonts.com/css/avenir-next-lt-pro');
@import url('https://fonts.cdnfonts.com/css/heima');
$fa-font-path: './fontawesome/webfonts';
@import "./fontawesome/scss/fontawesome.scss";
@import "./fontawesome/scss/solid.scss";
@import "./fontawesome/scss/brands.scss";
@import url('https://fonts.cdnfonts.com/css/noto-serif');

$golden-background-color : #CBAC59;
$white-background-color: white;
$container-background-color: #F6F6F6;
$background-color-2: #e7eaed;
$primary-color: #212529;
$secondary-color: #212529;
$font-color: #212529;
$noto-family: 'Noto Serif', serif; 
$heima-family: 'Heima', sans-serif; 
$avenir-next-lt-pro-family: 'Avenir Next LT Pro', sans-serif;
$mobile-width: 576px;
$medium-width: 720px;

body {
    font-family: $noto-family;
    //font-size: larger;
    background-color:$white-background-color;
    color:#1D242C;
    padding:0px;
    margin:0px;
    
}

.grayed{
    background-color: $container-background-color!important;
}


.shrink{
    @media (not (max-width:$mobile-width)) {
        display: none;
    }
}

.wide{
    @media (max-width:$mobile-width) {
        display: none;
    }
}

.emphasis{
    @extend b;
}

.pointer{
    cursor: pointer;
}

/*****
* Buttons rendering
*/
a.rounded-button{
    text-decoration: none;
    padding: 5px 5px;
    border-radius: 7px;
    cursor: pointer;
    font-size: large;
}

a.classic-button{
    background-color: white;
    border: 1px $golden-background-color solid;
    color: black;

    &:hover{
        background-color: $golden-background-color;
        transition: ease 1s;
    }
}

a.transparent-button{
    border: 1px white solid;
    color:white;

    &:hover{
        background-color: $golden-background-color;
        transition: ease 1s;
    }
}

.border-black{
    border: black solid 1px !important;
}

a.reverse-button{
    border: 1px white solid;
    color: white!important;

    &:hover{
        background-color:white;
        color: $golden-background-color!important;
        transition: ease 1s;
    }
}


.content{
    min-height: calc(100vh - 365px);//Footer filler
}

h1, h2, h3, h4, h5 {
    color: $secondary-color;
    font-family: $avenir-next-lt-pro-family;
}

.depot-info{
    @extend %fa-icon;
    @extend .fas;
    font-weight: lighter;
    text-align: justify;
    &::before{
        content:fa-content($fa-var-info-circle);
        color:#bea252!important;
        margin-right: 5px;
    }
}

.cursor-pointer{
    cursor: pointer;
    &:hover{
        background-color: lightgray;
    }
}

.link-with-arrow{
    @extend %fa-icon;
    @extend .fas;
    color:#CBAC59;
    font-weight: bold;
    font-size: x-large;

    &::before{
        content:fa-content($fa-var-arrow-right);
        color:#bea252!important;
        margin-right: 5px;
    }
}

.container-spacing{
    margin-top: 10px;
    padding-top: 20px;
    padding-bottom: 10px;
}

.page-container-template{
    margin: auto!important;
}

.page-title-and-introduction{
    .page-title{
        margin: 20px 0px;
        @extend h3;
        text-align:center;
        font-weight: bolder;
    }

    .page-introduction{
        @extend .container;
        @extend h4;
        @extend .col-md-12;
        
        @media (not (max-width:$mobile-width)) {
            text-align: justify;
        }        
        @media (max-width:$mobile-width) {
            text-align: left;
        }        
    }
}

.social-page-container,
.worship-page-container,
.main-page-container
{
    .buttons-panel{
        padding-top: 20px;
        @extend .container;
        @extend .col-md-12;

        Button{
            box-sizing: border-box;
            margin: 5px 5px;
        }
    }
}

/* Banners pictures */
.main-page-container {
    .top-banner-image{
        background-image: url("../../Assets/img/banner.jpg");
    }
}

.request-page-container {
    .top-banner-image{
        background-image: url("../../Assets/img/submit-banner.jpg");
    }
}

.authentication-page-container,
.user-lounge-page-container {
    .top-banner-image{
        background-image: url("../../Assets/img/lounge-banner.jpg");
    }
}

.mailing-page-container {
    .top-banner-image{
        background-image: url("../../Assets/img/banner.jpg");
    }
}

.contact-page-container{
    .top-banner-image{
        background-image: url("../../Assets/img/contact_us.jpg");
    }
}

.participation-page-container{
    .top-banner-image{
        background-image: url("../../Assets/img/prayers_festival.jpg");
    }
}

.subscribe-page-container {
    .top-banner-image{
        background-image: url("../../Assets/img/subscribe.jpg");
    }
}

.about-page-container {
    .top-banner-image{
        background-image: url("../../Assets/img/about_us.jpg");
    }
}

.social-page-container {
    .top-banner-image{
        background-image: url("../../Assets/img/promote-banner.jpg");
    }
}

.worship-page-container {
    .top-banner-image{
        background-image: url("../../Assets/img/worship_banner.jpg");
    }
}

.bible-page-container {
    .top-banner-image{
        background-image: url("../../Assets/img/read_bible_banner.jpg");
    }
}

.main-page-container,
.about-page-container,
.request-page-container,
.authentication-page-container,
.mailing-page-container,
.user-lounge-page-container,
.contact-page-container,
.social-page-container,
.worship-page-container,
.subscribe-page-container,
.participation-page-container,
.bible-page-container{
    @extend .page-container-template;
    
    Button.btn {
        @extend .col1 !optional;
        //max-width: 200px;
        //margin-top: 20px;
        background-color: white;
        border: 1px #CBAC59 solid;
        color: black;

        &:hover{
            background-color: #CBAC59;
            color: white;
            transition: ease 0.5s;
        }
    }

    .cropped {
        object-fit: none; /* Do not scale the image */
        object-position: center; /* Center the image within the element */
        width: 100%;
        max-height: 450px;
    }
    
    .top-banner-image{
        margin-top: -63px;/*sub menu fixe*/
        /*background-image: url("../../Assets/img/banner.jpg");*/
        background-position: center;
        background-size: cover;
        height: 600px;
        max-height: 450px;
        width: 100%;
        img{
            @extend .cropped;
        }
    
        div.nested-title{
            position: relative;
            z-index: 500;
            font-size: 2rem;
            font-weight: 900;
            color: white;
            top: 50%;
            display: block;
            text-align: center;
        }

        .dark{
            color:#1fb4ff!important;
        }
    }


    //Top support us video container
    .top-event-trailer-container{
        height: 500px; 
        overflow: hidden;
        position: relative;
        margin: 0px;
        padding: 0px;
        background-color: black;
        @extend .row;
        @extend .container-spacing;

        .player-wrapper{
            @extend .col-md-12;

            p{
                text-align: justify;
                padding: 0px 5px;
            }
        }

        .react-player-classname{
            padding: 0px;
            margin: 0px;
            background-color: black;
        }

        .player-legend{
            color: #1d242a;
            font-style: italic;
        }
    }

    .body-page-container{
        max-width: 1024px;
        margin: auto;
        .welcome-title{
            margin-top: 10px;
            div:first-child {
                @extend h3;
                text-align:center;
                font-weight: bolder;
            }
        }

        .welcome-sub-title{
            margin-top: 40px;
            div:first-child {
                @extend h4;
                text-align:left;
                font-weight: bolder;
            }
        }

        .edification{
            @extend .container;
            @extend .container-spacing;
            text-align: justify;
            
            margin: 30px auto;

            blockquote{
                font-style: italic;
                margin-left: 1em;
                b{
                    color: #006699
                }                
            }

            h3{
                font-size: larger;
            }

            h3::after{
                content: ("\A");
                white-space: pre;
            }
        }

        .introduction-container{
            @extend .container;
            @extend .container-spacing;

            .introduction-columns-container{
                @extend .row;

                .introduction-column{
                    @extend .col-sm;
                    text-align: left;
                    .intro-title {
                        @extend h5;
                    }

                    .signature{
                        @extend .align-items-end;
                        font-style: italic;
                        font-weight: bold;
                        text-align: right;
                        vertical-align: en;
                        margin-top: 10px;
                    }
                }
            }

            .donate-columns{
                background-color: lavender;
                display: flex;
                flex-direction: row;
                @media (max-width:$medium-width) {
                    flex-direction: column!important;
                }
            
                .donate-item {
                    flex-direction: column;
                    flex: 1 1 30%; /*grow | shrink | basis */
                    text-align: left;
                    padding: 5px;
                    display: grid;

                    .hello-asso{
                        background-image: url("../../Assets/img/helloasso-logo.png");
                        width: 220px;
                        height: 48px;    
                        display: block;
                        background-size: contain;
                    }
                }
            
                .align-end{
                    align-items: flex-end;
                }
            }            
        }        

        .acteurs-fluid-container{
            width: 100%;                
            background-color: $container-background-color;
            text-align: center;
            .acteurs-container{
                @extend .container;
                @extend .container-spacing;
                width: 100%;
                
                background-color: $container-background-color;
                text-align: center;

                .acteurs-title {
                    @extend h3;
                }

                .acteurs-introduction{
                    text-align: center;
                }

                .acteurs-columns-container{
                    @extend .row;
                    @extend .container-spacing;

                    .acteur-column{
                        @extend .col-sm;
                        text-align: left;
                        img {
                            width: 100%;
                            height: 150px;
                        }
                        /* Obsolete
                        .acteur-name {
                            @extend h4;
                            text-align: center;
                            margin-top: 5px;
                        }*/

                        .acteur-description{
                            margin-top:5px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            text-align: left;
                        } 
                    }
                }
            }
        } 

        .rubriques-fluid-container{
            width: 100%;                
            background-color: $container-background-color;
            text-align: center;
            .rubriques-container{
                @extend .container;
                @extend .container-spacing;
                width: 100%;
                
                background-color: $container-background-color;
                text-align: center;

                .rubriques-title {
                    @extend h3;
                }

                .rubriques-introduction{
                    text-align: center;
                }

                .rubriques-columns-container{
                    @extend .row;
                    @extend .align-items-start;
                    @extend .container-spacing;

                    .spacing-button{
                        @extend .justify-content-around;
                        /*@extend .justify-content-md-around;
                        @extend .align-content-sm-stretch;*/
                        display: flex;
                    }

                    .rubrique-column{
                        @extend .col-sm;

                        .rubrique-description{
                            text-align: left;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            margin-top: 5px;
                        } 

                        Button {
                            @extend .col-sm-12;
                            margin-top: 20px;
                        }
                    }
                }
            }
        } 

        .CircularProgressbar{
            height: 70%;
        }

        .progress-fluid-container{
            width: 100%;                
            //background-color: $container-background-color;
            text-align: center;            

            .progress-columns-container{
                padding: 15px;
                @extend .row;
                @extend .container-spacing;

                .progress-column{
                    @extend .col-sm;

                    .animated-container{
                        color:$golden-background-color;
                        justify-content: center;
                    }

                    .progress-sub-title{
                        text-align: center;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin-top: 5px;
                    } 
                }
            }

        }        
        
        .news-fluid-container{
            @extend .container-fluid;
            background-color: white;
            .news-container{
                @extend .container;
                @extend .container-spacing;
                
                .news-columns-container{
                    @extend .row;
                    @extend .align-top;
                    border-top: darkgray solid 1px;
                    padding-top:10px;

                    .news-column{
                        @extend .col-sm;

                        .latest-news-teaser{
                            @extend h4;
                            text-align:left;
                        }

                        .news-text{
                            text-align: left;
                        }
                    }
                }
            }
        }

        .verse-fluid-container{
            @extend .container-fluid; 
            background-color: $container-background-color;
            .verse-container{
                @extend .container;
                @extend .container-spacing;
                
                .verse-title{
                    @extend h3;
                    text-align: center;
                }

                .verse-columns-container{
                    @extend .row;
                    @extend .align-top;
                    border-top: darkgray solid 1px;
                    padding-top:10px;

                    .verse-column{
                        @extend .col-sm;

                        .verse-ref{
                            @extend h2;
                            text-align: left;
                        }

                        .verse-text{
                            text-align: left;
                            q {
                                width: 100%;
                                text-align: rig;
                            }
                        }
                    }
                }
            }
        }
    }
}


.daily-worship-container{
    @extend .edification;
    background-color: rgb(248, 248, 248);
    &:after{
        content: ("Copyright © 2023 by Ellen G. White. All Rights Reserved.");
        /*font-family: $heima-family;*/
        font-size: x-large;
        text-align: right!important;
        color: #006699;
    }

}

.bible-container{
    @extend .edification; 
    
    .bible-nav-bar{
        display:flex;
        flex-direction: row;
    }
    /*
    .bible-bar-active-selection{
        @extend .row;
        padding: 0px;
        justify-content: center;
        background-color: whitesmoke;
        box-shadow: 0px 2px 5px 0px rgb(0 0 0 / 30%);
        border-top: 1px solid #EDECEC;
        height: 58px;
        display: flex;
        align-items: center;

        div {
            line-height: 100%;
            text-align: center;
            @extend h3;
            //background-color: red;
            padding: 0px;
            margin: 0px;
        }
    } */ 

}

.bible-component{
    margin-top: 10px;
}


.footer-fluid-container{
    @extend .container-fluid;
    background-color: #1d242a;
    color:#FFFFF0;
    padding: 20px 0px;
    margin-top:10px;
    .footer-centered-container{
        @extend .container;
        .footer-row{
            @extend .row;
            div.association-address{
                @extend .col-12;
                ul{
                    padding-left: 0px;
                    list-style:none;
                    text-align:"left";
                }
            }

            a{
                text-decoration: none;
                color: #FFFFF0;
                border: white 1px solid;
                padding: 5px;
                line-height: 50px;
                border-radius: 7px;

                &:hover{
                    background-color: #cbac59;
                    transition: ease 1s;
                }
            }

            div.copyright{
                @extend .col-12;
                padding-top: 50px;
                text-align: center;
                opacity: .5;
            }
        }
    }
}
 
.help-assist {
    position: fixed;
    background-color: rebeccapurple;
    bottom: 32px;
    right: 34px;
    height: 56px;
    width: 260px;
    color: white;
    padding: 15px;
    border-radius: 50px;
    transition: ease-in 0.7s;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    z-index: 998;
}

.assist-close{
    width: 50px;
    //display: none;
}


/* --------------- EVENTS/MEDIA ----------------------*/
.pic{
    width:100%;
    cursor: pointer;
  }
/*
.react-pdf__Page{
height: 250px;
width: 207px;
}*/

.react-pdf__Page__canvas{
width:100%!important;
height: 100%!important;
}

.pdf-viewver{
    overflow: hidden;
    position: absolute;
    top: 0px;

    .pdf-top-bar{
        height: 56px;
        padding: 2px;
        position: fixed;
        display: flex;
        color: white;
        background-color: black;
        top:0px;
        left:0px;
        right: 0px;
        z-index: 502;

        .pdf-navigator{
            display: flex;
            width: 100%;
            justify-content: flex-start;
            align-items: center;

            .left-nav{
                width: 70%;
                display: flex;

                .pdf-page-position{
                    display: flex;
                    padding: 0px 10px;
                    align-items: center;
                }
            }

            .pdf-title{
                display: flex;
                justify-content: flex-end;
                width: 30%;

                div{
                    width: 100%;
                    white-space: nowrap;
                    text-overflow: ellipsis;
    
                }
            }
    
        }
    } 
}

.events-fluid-container{
    width: 100%;                
    text-align: center;
    .events-container{
        @extend .container;
        @extend .container-spacing;
        width: 100%;
        text-align: center;

        .events-title {
            @extend h3;
            margin-bottom: 20px;
        }

        .page-introduction,
        .events-introduction{
            @extend h5;
            text-align: justify;
            margin: 20px auto;
        }

        .events-columns-container{
            @extend .row;
            //@extend .align-items-end;
            @extend .container-spacing;
            @media (not (max-width:$mobile-width)) {
                height: 500px;
            }

            .event-column{
                @extend .col-md-6;
                @extend .col-lg-4;
                text-align: left;
                margin: 10px 0px;
                
                @media (max-width:$mobile-width) {
                    padding: 10px;
                }

                img {
                    width: 100%;
                }

                .event-background-image{
                    @extend .align-items-end;
                    background-position: top;
                    height: 436px;
                    max-height: 436px;
                    width: 100%;
                    background-size: cover;
                    box-shadow: 0px 3px 29px 0px rgba(0, 0, 0, 0.35);
                    display: flex;                            

                    .event-override-image-container{
                        align-items: flex-end !important;
                        position: relative;
                        z-index: 500;
                        color: black;
                        height: 50%;
                        vertical-align: bottom;
                        text-align: left;
                        background-color: white;
                        padding: 20px;
                        cursor: pointer;
                        overflow: hidden;
                        width: 100%;
                        @media (max-width:$mobile-width) {
                            display: none;
                        }
                    }
                }

                .event-background-image:hover .event-override-image-container{
                    height: 10%;
                    opacity: 0.5;
                    transition: ease 2s;
                }

                .event-name {
                    @extend h4;
                    text-align: center;
                }

                .event-description{
                    //height: 200px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                } 
            }
        }
    }
} 