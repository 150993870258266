.share{
    margin: 0px 3px;
}

.whatsapp{
    background-color: rgb(19, 99, 9)!important;
    color: white!important;
}

.twitter{
    background-color: rgb(29, 155, 240)!important;
    color: white!important;
}

.facebook{
    background-color: white!important;
    color: #1877f2!important;
}

.linkedin{
    background-color: #0A66C2!important;
    color: white!important;
}

.mail-alert{
    background-color: orange!important;
    color: white!important;
    display: inline!important;
}